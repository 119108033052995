<template>
  <div class="app-index">
    <AppHead />
    <AppNav />
    <el-row
      class="app-content">
      <el-col :span="24">
        <div ref="appContent">
          <router-view :key="$route.path" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import AppHead from './AppHead'
import AppNav from './AppNav'
import { provide, ref } from 'vue'

const appContent = ref(null)
provide('appContent', appContent)

</script>

<style lang="scss" scoped>
  .app-content {
    padding: 15px;
    float: right;
    width: calc(100% - 240px);
    height: calc(100vh - 46px);
    overflow-y: scroll;
  }
</style>
