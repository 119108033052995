<template>
  <div class="app-nav">
    <el-menu
      :default-active="indexOfActiveNavItem"
      :collapse="isCollapse"
      class="full-height">
      <Suspense>
        <AppNavMenuItem
          v-for="({name, title, iconPostfix, operation, children}, key) in treeData"
          :key="key+name"
          :name="name"
          :title="title"
          :icon-postfix="iconPostfix"
          :operation="operation"
          :children="children"
          :nav-list="navList" />
      </Suspense>
    </el-menu>
  </div>
</template>

<script setup>
import AppNavTreeData from './AppNavTreeData'
import AppNavMenuItem from './AppNavMenuItem'
import { ref, reactive, computed } from 'vue'
import { useRoute } from 'vue-router'

const isCollapse = ref(false)

const treeData = reactive(AppNavTreeData)
const navList = computed(() => createNavList(treeData))
function createNavList (data = [], parentIndex = '') {
  if (data.length === 0) return data
  const createdNavList = []
  data.forEach((menuItem, index) => {
    const navItem = {
      menuItemName: menuItem.name,
      index: index.toString()
    }
    if (parentIndex) navItem.index = `${parentIndex}-${index}`
    createdNavList.push(navItem)
    if (menuItem.children && menuItem.children.length > 0) {
      createdNavList.push(...createNavList(menuItem.children, index))
    }
  })
  return createdNavList
}

const route = useRoute()
const indexOfActiveNavItem = computed(() => {
  const activeMenuItem = navList.value.find(el => el.menuItemName === route.name)
  return (
    activeMenuItem ? activeMenuItem.index : '0'
  )
})
</script>

<style lang="scss">
  .app-nav {
    position: relative;
    width: 240px;
    height: calc(100vh - 46px);
    float: left;

    &__link {
      text-decoration: none;
      display: block;
      text-align: left;
      color: #343a40;
      width: 100%;
    }
    .router-link-active {
      &.disabled-events {
        pointer-events: none;
      }
      span {
        color: #1B71C7;
      }
    }
  }
</style>
