export default [
  {
    name: 'ReportList',
    title: 'Отчеты',
    iconPostfix: 'report',
    operation: ''
  },
  {
    name: 'Thesaurus',
    title: 'Справочники',
    iconPostfix: 'thesaurus',
    operation: '',
    children: [
      {
        name: 'ThesaurusReportTypes',
        title: 'Типы отчетов',
        iconPostfix: 'list'
      }
    ]
  }
]
