<template>
  <el-menu-item
    v-if="isLinkWithNoChildren"
    :index="menuItemIndex"
    class="navigation-item">
    <router-link
      class="app-nav__link"
      :to="{ name: props.name }">
      <i :class="`el-icon nav-icon nav-icon__${props.iconPostfix}`" />
      <span>{{ $t(props.title) }}</span>
    </router-link>
  </el-menu-item>
  <el-sub-menu
    v-else-if="isMenuItemWithChildren"
    :index="menuItemIndex"
    class="navigation-item">
    <template #title>
      <i class="nav-icon nav-icon__thesaurus" />
      {{ $t(props.title) }}
    </template>
    <AppNavMenuItem
      v-for="submenu, index in props.children"
      :key="index+submenu.name"
      :name="submenu.name"
      :title="submenu.title"
      :icon-postfix="submenu.iconPostfix"
      :operation="submenu.operation"
      :children="submenu.children"
      :nav-list="props.navList" />
  </el-sub-menu>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router';
    
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  iconPostfix: {
    type: String,
    required: true
  },
  operation: {
    type: String,
    default: ''
  },
  children: {
    type: Array,
    default: () => []
  },
  navList: {
    type: Array,
    default: () => []
  }
})

const menuItemIndex = computed(() => {
  const currentMenuItem = props.navList.find(el => el.menuItemName === props.name)
  if (currentMenuItem) return currentMenuItem.index
  return '0'
})

const router = useRouter()
const isRouteExists = computed(() => {
  try {
    return !!router.resolve({ name: props.name })
  } catch {
    return false
  }
})
const isLinkWithNoChildren = computed(() => {
  return ((!props.children || !props.children.length) && isRouteExists.value)
})
const isMenuItemWithChildren = computed(() => {
  return props.children && props.children.length
})

</script>

<style lang="scss" scoped>
  .navigation-item {
    padding-right: 0 !important;
  }
  .nav-icon {
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    height: 24px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: left center;
    display: inline-block;

    &__report {
      background-image: url('../assets/icons/report.svg');
      background-size: 20px;
    }

    &__thesaurus {
      background-image: url('../assets/icons/thesaurus.svg');
      background-size: 16px;
    }

    &__list {
      background-image: url('../assets/icons/list.svg');
      background-size: 21px;
    }
  }
</style>
