<template>
  <div class="flex">
    <span
      v-for="(breadcrumb, index) in breadcrumbsList"
      :key="index"
      class="wrapper">
      <div v-if="breadcrumb.isLink">
        <router-link
          :to="{ name: breadcrumb.routeName }"
          class="breadcrumb breadcrumb_link">
          {{ breadcrumb.title }}
        </router-link>
      </div>
      <span
        v-else
        class="breadcrumb">
        {{ breadcrumb.title }}
      </span>
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const route = useRoute()
const { t } = useI18n()
const breadcrumbsList = computed(() => {
  let routesWithBreadcrums = route.matched.filter(el => (el.name !== undefined) && el.meta.breadcrumb)
  const breadcrumbs = routesWithBreadcrums.map(route => {
    return {
      title: t(getBreadcrumbTitle(route)),
      routeName: route.name,
      isLink: isBreadcrumbLink(route, routesWithBreadcrums)
    }
  })
  routesWithBreadcrums = []
  return breadcrumbs
})

function getBreadcrumbTitle (route) {
  let title = route.meta.title || ''
  const isDynamic = route.meta.breadcrumb.dynamic
  if (isDynamic) {
    let dynamicTitle = route.meta.breadcrumb.getBreadcrumbTitle()
    if(dynamicTitle) title = dynamicTitle
  }
  return title
}

function isBreadcrumbLink (route, routeList) {
  let isLink = true
  const isThisLastRouteInList = routeList[routeList.length - 1].name === route.name
  if(isThisLastRouteInList) {
    isLink = false
  }
  const isThisRouteRedirectToSomeRouteInList = routeList.some(el => route.redirect?.name === el.name)
  if(isThisRouteRedirectToSomeRouteInList) {
    isLink = false
  }
  return isLink
}

</script>

<style lang="scss" scoped>

$color: #ffffff;
$font-size: 16px;

.breadcrumb {
  color: $color;
  text-decoration: none;
  margin-right: 10px;
  display: inline-block;
  font-size: $font-size;
  &_link {
    &:hover {
      text-decoration: underline;
    }
  }
}
.wrapper {
  &::before {
    content: '/';
    display: inline-block;
    margin-right: 10px;
    color: $color;
    font-size: $font-size;
  }

  &:first-child::before {
    display: none;
  }
}
</style>