<template>
  <el-row
    :gutter="20"
    class="app-header">
    <el-col :span="4">
      <div class="app-header__logo">
        АСУС 2.0
      </div>
    </el-col>
    <el-col :span="6">
      <WidgetBreadcrumbs />
    </el-col>
    <el-col
      :span="13"
      align="right">
      <div class="app-header__user">
        {{ getUserData.name || '' }}
      </div>
    </el-col>
    <el-col
      :span="1"
      align="right">
      <i
        class="app-header__logout"
        @click="_userLogoutPromise" />
    </el-col>
  </el-row>
</template>

<script>
import WidgetBreadcrumbs from '@/components/widgets/WidgetBreadcrumbs.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppHead',
  components: {
    WidgetBreadcrumbs
  },
  computed: {
    ...mapGetters('oauth', ['getUserData', 'getTheme'])
  },
  methods: {
    ...mapActions('oauth', ['userLogout', 'changeTheme']),

    _userLogoutPromise () {
      return this.userLogout({}).then().finally(() => {
        this.$router.push({ name: 'Auth' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-header {
    background-color: #1B71C7;
    padding: 12px 15px;

    &__line {
      width: 100%;
    }

    &__logo {
      float: left;
      text-align: left;
      text-transform: uppercase;
      color: #fff;
      font-size: 18px;
    }

    &__logout {
      cursor: pointer;
      display: block;
      width: 22px;
      height: 22px;
      background: url('../assets/icons/logout.svg') no-repeat;
    }

    &__user {
      color: #fff;
      line-height: 22px;
    }

    &__theme {
      padding: 2px;
      height: 18px;
      width: 18px;
      display: block;
      float: right;
      color: #fff;
      cursor: pointer;
      margin-right: 15px;
    }
  }
</style>
